.input {
  display: none;
}

.wrapper {
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  padding: 0.5rem;
}

.placeholder {
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #777;
  font-weight: bold;
  height: 4rem;
}

.preview {
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  position: relative;
  overflow: hidden;
}

.preview > img {
  max-height: 100%;
  max-width: 100%;
}

.desc {
  color: #4f4f4f;
}

.remove-btn {
  padding: 0.25rem;
  background-color: #fff;
  line-height: 1;
  border-radius: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  color: red;
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
}

.help {
  font-size: 0.8em;
  color: #777;
  margin-bottom: 0;
}
