.input {
  display: flex;
}

.input > input {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #fff !important;
}

.input > button {
  border: 1px solid #ccc;
  border-left: none;
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-down > input {
  border-bottom-left-radius: 0;
}

.input-down > button {
  border-bottom-right-radius: 0;
}

.list {
  background-color: #efefef;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border: 1px solid #ccc;
  border-top: none;
  z-index: 100;
  position: absolute;
  width: calc(100% - 30px);
}

.list > ul {
  list-style: none;
  margin-bottom: 0;
  padding: 0.5rem;
  max-height: 10rem;
  overflow-y: auto;
}

.list > ul > li {
  background-color: #fff;
  padding: 0.25rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #efefef;
  cursor: pointer;
}

.list-empty {
  padding: 0.5rem;
}

.list-empty > div {
  background-color: #fff;
  padding: 0.25rem 1rem;
  color: #4f4f4f;
}
