.divider {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.line {
  display: block;
  height: 1px;
  background-color: #ccc;
  flex: 1;
}

.text {
  padding: 0 1rem;
  color: #ccc;
}