.map {
  height: 70vh;
}

.separator > h6 {
  border-bottom: 1px solid #ccc;
}

.buttons {
  margin-bottom: 1rem;
}

.buttons > input {
  display: none;
}

.buttons > label {
  margin-bottom: 0;
}

.buttons > label,
.buttons > div,
.buttons > button {
  margin-right: 0.5rem;
}

.linelist > label {
  margin-bottom: 0;
  font-weight: bold;
  font-size: 0.9em;
  color: #4f4f4f;
}

.linelist > ul {
  padding: 0;
  list-style: none;
  max-height: 35vh;
  overflow-y: auto;
}

.linelist > ul > li {
  padding: 0.25rem 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.linelist > ul > li:nth-child(odd) {
  background-color: #f5f5f5;
}

.li-button {
  cursor: pointer;
}

.collisions {
  display: inline-block;
}

.collisions > input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
  display: inline-block;
  vertical-align: middle;
  width: auto;
}

.collisions > button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
}

.unify-btn {
  display: block;
  margin-left: auto;
}

.buttons-right {
  text-align: right;
}

.metros {
  background-color: #fff !important;
  border-left: none;
  border-radius: 0;
  border-right: none !important;
  padding: 0.375rem 0rem;
  width: 4rem !important;
}
