.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.card {
  z-index: 99999;
  overflow: hidden;
  width: 28em;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttons > * {
  margin: 0 .5rem;
  font-weight: bold;
}

.title {
  font-weight: bold;
  text-align: center;
  font-size: 2em;
  color: #4f4f4f;
}

.message {
  text-align: center;
  font-size: 1.2em;
  margin-bottom: 2rem;
}