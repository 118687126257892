.infocol {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.infocol > span:first-child {
  color: #4F4F4F;
  font-weight: bold;
  font-size: 0.85em;
}