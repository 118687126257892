.map {
  height: 70vh;
}

.lines-list {
}

.lines-list > label {
  margin-bottom: 0;
  font-weight: bold;
  font-size: 0.9em;
  color: #4f4f4f;
}

.lines-list > ul {
  max-height: 57vh;
  overflow-y: scroll;
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.lines-list > ul > li {
  padding: 0.25rem 0.5rem;
}

.lines-list > ul > li:nth-child(odd) {
  background-color: #f5f5f5;
}

.lines-list > ul > li > div {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lines-list > ul > li > div:hover {
  color: #ff6a29;
}

.lines-list > ul > li > div > span {
  padding-right: 0.5rem;
  display: flex;
  align-items: center;
}

.lines-list > ul > li > div > div {
  display: flex;
  align-items: center;
}

.lines-list > ul > li > ul {
  list-style: none;
  margin-bottom: 0;
  padding: 0.25rem 0 0.25rem 1.5rem;
  border-top: 1px solid #ccc;
}

.lines-list > ul > li > ul > li > a {
  color: #4f4f4f;
  display: block;
}

.lines-list > ul > li > ul > li > a:hover {
  color: #0056b3;
  text-decoration: none;
}

.new-line {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  padding-left: 0;
}

.empty {
  background-color: #f5f5f5;
  border-radius: 0.25rem;
  padding: 0.5rem;
  color: #4f4f4f;
}
