.login {
  background-color: #f2f2f2;
  min-height: 100vh;
}

.padding {
  padding: 2.5rem 0;
}

.height100 {
  height: 100%;
}

.logo {
  width: 60%;
  margin: auto;
  display: block;
  margin-bottom: 1rem;
}

h1 {
  font-weight: bold;
  font-size: 1.5em;
  color: #4f4f4f;
  margin-bottom: 1.5rem;
}

.link {
  margin-bottom: 1rem;
  display: block;
}

.mobile-card {
  border: none;
}

.mobile-body {
  z-index: 10;
}

.otro-body {
  flex: 1 1 auto;
  padding: 1.25rem;
  padding-top: 2rem;
  background-color: #f2f2f2;
}

.footer {
  text-align: center;
  padding-top: 1rem;
}

.footer > :global.btn {
  color: #777;
}

.link-list {
  padding: 0;
  list-style: none;
}
