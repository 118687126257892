.sidebar {
  min-width: 250px;
  max-width: 250px;
  height: calc(100vh - 50px);
  background-color: #fff;
  border-top: 2px solid #f5f5f5;
  display: flex;
  flex-direction: column;
  /* Box shadow */
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.1);
  /* Transitions */
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.links {
  list-style: none;
  padding: 0;
  overflow-y: auto;
  margin-bottom: 0;
  height: 100%;
  padding: 2.5rem 0;
}

.link {
  width: 100%;
  padding: .5rem 1.6rem;
  display: flex;
  align-items: center;
  font-size: 1.1em;
  margin-bottom: 1rem;
  background-color: #ccc;
  color: #4f4f4f;
  font-weight: bold;
}

.link:hover {
  text-decoration: none;
  color: #fff;
  background-color: #e94d19;
}

.link > svg {
  width: 25px;
  margin-right: 1rem;
}

.collapsed {
  max-width: 80px;
  min-width: 80px;
}

.collapsed .link {
  text-align: center;
  overflow: hidden;
  word-wrap: break-word;
}

.collapsed .link > span {
  display: none;
}

.collapsed .link > svg {
  margin: 0;
  width: 100%;
  font-size: 1.25em;
}

.active {
  background-color: #e94d19;
  color: #fff;
}