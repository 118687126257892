/****************************************/
/** Fonts */
/****************************************/

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato/Lato-Regular.ttf');
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato/Lato-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: 'Kelson';
  src: url('./assets/fonts/KelsonSans.otf');
}

/****************************************/
/** General */
/****************************************/

body {
  font-family: 'Lato';
}

.mirai-shadow {
  -webkit-box-shadow: 2px 4px 6px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 2px 4px 6px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 2px 4px 6px 0px rgba(0, 0, 0, 0.25);
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: bold;
  color: #4f4f4f;
}

.title {
  font-weight: bold;
}

.back {
  padding-left: 0;
}

.label {
  margin-bottom: 0;
  font-weight: bold;
  font-size: 0.9em;
  color: #4f4f4f;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 0.5rem;
}

.section-header>h2 {
  margin-bottom: 0;
}

.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0 !important;
}

/****************************************/
/** Table */
/****************************************/

.table-une {}

.table-une>.table-controls>.left>.form-group {
  padding-left: 0;
}

.table-une>.table-controls>.right>button {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.table-une .table-controls>.right>button:hover,
.table-une .table-controls>.right>button:focus,
.table-une .table-controls>.right>button:active {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.table-badge {
  min-width: 6rem;
}

.wafotable .controls .left {
  flex: 1;
}

.table-une .btn {
  margin-right: .25rem;
}

.table-une .btn:last-child {
  margin-right: 0;
}

/****************************************/
/** Details */
/****************************************/

.item-desc {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.item-desc .subtitle {
  color: #4f4f4f;
  font-weight: bold;
}

.item-desc .value {
  color: #4f4f4f;
}

/****************************************/
/** Buttons */
/****************************************/

.btn {
  border-radius: 0.25rem;
}

.btn-submit {
  font-weight: bold;
  padding: 0.375rem 2.5rem;
  margin-bottom: 0.75rem;
}

.btn-submit-center {
  width: 50%;
  margin: auto;
  margin-top: 1.5rem;
  display: block;
}

.btn-link-eliminar {
  color: #dc3545;
}

.btn-link-eliminar:hover {
  color: #c82333;
}

.btn-une {
  color: #fff;
  background-color: #e94d19;
  border-color: #e94d19;
}

.btn-une:hover,
.btn-une:focus,
.btn-une:active,
.btn-une:disabled {
  color: #fff;
  background-color: #d14516;
  border-color: #d14516;
}

.btn-une-g {
  color: #fff;
  background-color: #66ca21;
  border-color: #66ca21;
}

.btn-une-g:hover,
.btn-une-g:focus,
.btn-une-g:active,
.btn-une-g:disabled {
  color: #fff;
  background-color: #5bb51d;
  border-color: #5bb51d;
}

.btn-selectroute {
  padding: 0.375rem 3rem;
  font-weight: bold;
}

.btn-routeselected {
  background-color: #e94d19;
  border-color: #e94d19;
}

.btn-routeselected:disabled,
.btn-routeselected:active,
.btn-routeselected:focus,
.btn-routeselected:hover {
  background-color: #d14516 !important;
  border-color: #d14516 !important;
}

.btn-fb {
  background-color: #4267b2;
  color: #fff;
  display: flex;
  align-items: center;
  width: 100%;
}

.btn-fb:hover {
  color: #fff;
}

.btn-fb>svg {
  font-size: 1.5em;
  margin-right: 1rem;
}

.btn-google {
  width: 100%;
}

.btn-fb,
.btn-google {
  margin-bottom: 0.75rem;
}

/****************************************/
/** Forms */
/****************************************/

.wafo-input label {
  margin-bottom: 0;
  font-weight: bold;
  font-size: 0.9em;
  color: #4f4f4f;
}

.wafo-input .errors {
  margin-top: 0.25rem;
  margin-bottom: 0;
  padding-left: 1.25rem;
  font-size: 0.85em;
  font-weight: bold;
  color: #eb5757;
  list-style-type: square;
}

.autocomplete-item {
  padding: 0.75rem 1rem;
  margin: 0;
  line-height: 1;
  border-bottom: 1px solid #f5f5f5;
}

.draftjsWrapper {
  border: 1px solid #ccc;
  border-radius: 0.25rem;
}

.draftjsToolbarWrapper {
  border-radius: 0 !important;
  border: none !important;
  border-bottom: 1px solid #ccc !important;
}

.draftjsEditorWrapper {
  padding: 0 1rem;
}

.form-separator {
  font-size: 1.2em;
  font-weight: bold;
  color: #ccc;
  margin-bottom: 1rem;
  border-bottom: 1px solid #ccc;
}

.bus-select {
  margin-bottom: 0;
}

/****************************************/
/** Markers */
/****************************************/

.simple-marker {
  width: 100%;
  height: 100%;
}

.arrow-marker {
  height: 30px;
  width: 30px;
  margin-left: 3px;
}

.bus-marker {
  width: 100%
}

/****************************************/
/** Animations */
/****************************************/

.animate-in {
  /* Safari, Chrome and Opera > 12.1 */
  -webkit-animation: fadein 0.5s;
  /* Firefox < 16 */
  -moz-animation: fadein 0.5s;
  /* Internet Explorer */
  -ms-animation: fadein 0.5s;
  /* Opera < 12.1 */
  -o-animation: fadein 0.5s;
  animation: fadein 0.5s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animate-out {
  /* Safari, Chrome and Opera > 12.1 */
  -webkit-animation: fadeout 0.5s;
  /* Firefox < 16 */
  -moz-animation: fadeout 0.5s;
  /* Internet Explorer */
  -ms-animation: fadeout 0.5s;
  /* Opera < 12.1 */
  -o-animation: fadeout 0.5s;
  animation: fadeout 0.5s;
}

@keyframes fadeout {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadeout {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

/* Internet Explorer */
@-ms-keyframes fadeout {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadeout {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
